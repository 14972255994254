import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Typing from "react-typing-animation";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import routes from "../routes";

const IndexPage = () => (
  <Layout wrapperClass="home-page">
    <Seo title="PDF service for software development teams" />
    <div className="container">
      <section className="hero">
        <div className="row">
          <div className="col-md-6">
            <h2>
              Dynamic PDF
              <br />
              <Typing speed={100} loop={true} cursorClassName="typing-cursor">
                {[
                  "Invoices",
                  "Packing Slips",
                  "Tickets",
                  "Reports",
                  "Letters",
                ].map((val, i) => (
                  <span key={i}>
                    {val}
                    <Typing.Backspace count={val.length + 1} delay={1500} />
                  </span>
                ))}
              </Typing>
              by API
            </h2>
            <h3>
              Take the pain out of creating beautiful, dynamic PDFs in your apps
            </h3>
            <Link to={routes.signup} className="btn btn-primary">
              Get started free
            </Link>
          </div>
          <div className="col-md-6 hero__image">
            <StaticImage
              src="../images/json-to-invoice.png"
              width={400}
              quality={95}
              objectFit="contain"
              placeholder="none"
              alt="Illustration representing JSON input data producing a PDF output"
            />
          </div>
        </div>
      </section>
    </div>
    <section className="product-highlights">
      <div className="container">
        <div className="row">
          <div className="col-md-6 product-highlights__api">
            <StaticImage
              src="../images/terminal-api.png"
              quality={95}
              placeholder="none"
              alt="Illustration representing JSON input data producing a PDF output"
            />
            <h4>PDF via API</h4>
            <p>
              Integrate with our <strong>PDF API</strong> in minutes and take
              the hassle out of generating the world's favourite document
              format.
            </p>
          </div>
          <div className="col-md-6 product-highlights__editor">
            <StaticImage
              src="../images/editor-laptop.png"
              quality={95}
              placeholder="none"
              alt="DocuZen template editor screenshot"
            />
            <h4>Simply powerful template editor</h4>
            <p>
              No design skills required (but flexible enough if you've got 'em).
              <br />
              You can also choose from our professionally designed pre-built
              library.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
